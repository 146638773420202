@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Handlee&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Zeyada&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap);
html, 
body, 
#root, 
.app {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}


/* @media only screen and (min-width: 850px) { 
    .hero-image {
        width: 0%;
        display: flex;
        margin-left: 15ch;
        margin-top: 4ch;
    }

    .mobile-hero-image {
        display: none;
    }
} */


@media only screen and (min-width: 600px) { 
    .hero-image {
        width: 70%;
        display: flex;
        margin-left: 15ch;
        margin-top: 0ch;
    }

    .mobile-hero-image {
        display: none;
    }

    .image {
        width: 30%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: -10ch;
        margin-bottom: -10em;
    }
}

@media only screen and (max-width: 600px) {
    .hero-image {
        display: none;
        width: 100%;
    }

    .mobile-hero-image {
        display: flex;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: -45px;
        margin-top: 1%;
    }

    .image {
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 0;
        margin-bottom: -25px;
    }
}

.darkbg-link {
    text-decoration: none;
    color: #fff;
}

.darkbg-link:hover {
    color: #e7dcff;;
}

.lightbg-link {
    color: #8463be;
    text-decoration: none;
    font-family: "Quicksand";
}

.lightbg-link:hover {
    color: #b388ff;
}

.nav-link {
    text-decoration: none;
    color: #fff;
}

.nav-link:hover {
    color: #e7dcff;
}

.nav-setting-link {
    text-decoration: none;
    color: #000;
}

.nav-setting-link:hover {
    text-decoration: none;
}

.formLink {
    color: #8463be;
    text-decoration: none;
}

.formLink:hover {
    color: #b388ff;
}









